import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import T_ABOUT_QUESTIONS from "../../components/t_about_questions"

const AboutHotaruPage = () => (
  <Layout sectionClasses={"t-about"} dataNamespace={"about"}>
    <SEO title="About Hotaru" />

    <div className="t-about__kv">
      <picture>
        <source media="(max-width:959px)" srcSet={"/images/about/sp/about_top.jpg"} />
        <img  src={"/images/about/pc/about_top.jpg"} alt="" />
      </picture>
      <div className="t-about__kv__ttl p-title p-title--page">
        <h1>ABOUT</h1>
        <h3>HOTARU PERSONALIZED</h3>
      </div>
    </div>

    <div class="t-about__personalized">
      <h2>PERSONALIZED</h2><h3>SKIN CARE.</h3>
      <div className="t-about__personalized__txt">
        <img className="t-about__personalized__txt__bg" src={"/images/about/pc/initial_logo.png"} alt="" />
        <p>あなたのお肌に合わせて提案する、<br className="pc-hidden" />あなただけのスキンケア</p>
        <hr />
        <p className="t-about__personalized__txt__desc">「私の肌にはどんなスキンケアが必要なの？」<br className="sp-hidden" />
            多くの女性が抱えている疑問、不安、悩み。<br className="sp-hidden" />
            迷いを抱えながら行う美容で、あなたが本当の輝きを見失ってしまわないように。<br />
            <br />
            HOTARU PERSONALIZEDが届けたいのは、悩みからの解放。<br />
            独自の診断システムであなたのお肌の声を聞き、ぴったりのスキンケアを提案します。<br />
            <br />
            それはあなたの中から導き出した、あなただけの答え。<br />
            迷いのない心とお肌はもっと満たされ、もっと輝けるはず。<br />
        </p>
      </div>
      <div className="t-about__personalized__img">
        <img className="t-about__personalized__img__left" src={"/images/about/pc/personalized_1.jpg"} alt="" />
        <img className="t-about__personalized__img__right" src={"/images/about/pc/personalized_2.jpg"} alt="" />
      </div>
      <h4 className="absolute-txt rot-right sp-hidden">PERSONALIED SKIN CARE</h4>
    </div>

    <div className="t-about__answer">
      <div className="t-about__answer__block">
        <h2>THE ANSWER</h2>
        <h3>IS ALWAYS IN YOU.</h3>
        <h4 className="absolute-txt rot-left sp-hidden">THE ANSWER</h4>
      </div>
      <div className="t-about__answer__txt">
        <p>
          オンライン肌診断で作る<br className="pc-hidden" />オーダーメイドアイテム
        </p>
        <hr />
        <p>
          みんなに合うものはいらない。私に合うものが欲しい。<br />
          HOTARU PERSONALIZEDは、あなたのお肌が教えてくれる<br />
          たくさんの情報をもとに作るあなた専用のオーダーメイドスキンケア。<br />
          あなたにぴったりの処方でスキンケアアイテムをお届けします。<br />
        </p>
      </div>
      <div className="t-about__answer__img">
        <Link to={"/about/lm"} className="t-about__answer__img__left">
          <img src={"/images/top/pc/products_01.jpg"} alt="" />
          <h3>ローション・モイスチャライザーセット</h3>
          <h4>LOTION&MOISTURIZER SET</h4>
          <span className="t-about__answer__img__cat" >BASE CARE</span>
        </Link>
        <Link to={"/about/cleansing/"} className="t-about__answer__img__right">
          <img src={"/images/top/pc/products_05.jpg"} alt="" />
          <h3>クレンジング</h3>
          <h4>CLEANSING</h4>
          <span className="t-about__answer__img__cat" >PREMIUM CARE</span>
        </Link>
        <Link to={"/about/eyecream/"} className="t-about__answer__img__left" style={{ marginTop: '6vw'}}>
          <img src={"/images/top/pc/products_02.jpg"} alt="" />
          <h3>アイクリーム</h3>
          <h4>EYE CLEAM</h4>
          <span className="t-about__answer__img__cat" >PREMIUM CARE</span>
        </Link>
        <Link to={"/about/treatmentmask/"} className="t-about__answer__img__center" style={{ marginTop: '6vw'}}>
          <img src={"/images/top/pc/products_04.jpg"} alt="" />
          <h3>トリートメントマスク</h3>
          <h4>TREATMENT MASK</h4>
          <span className="t-about__answer__img__cat" >PREMIUM CARE</span>
        </Link>
      </div>
    </div>

    <div className="t-about__alltime">
      <img className="sp-hidden" src={"/images/about/pc/all_time.jpg"} alt="" />
      <div>
        <h2>ALL TIME</h2>
        <h3>SKIN CARE TIME.</h3>
        <p>24時間全てがスキンケアタイム</p>
        <hr />
        <p className="t-about__alltime__txt">あなたのお肌をつくるものは、<br />
          洗顔後のスキンケアだけではありません。<br />
          食事、睡眠、生活習慣。<br />
          あなたの毎日が、あなたのお肌を作っています。<br />
          <br className="sp-hidden" />
          HOTARU PERSONALIZEDのスキンケアは、<br className="sp-hidden" />
          あなたにぴったりのITEMだけでなく<br className="sp-hidden" />
          ビューティーライフ改善プログラムも提案。<br />
          <br className="sp-hidden" />
          あなたの24時間すべてが、<br />
          スキンケアタイムです。
        </p>
        <h4 className="absolute-txt rot-right sp-hidden">ALL TIME SKIN CARE TIME</h4>
      </div>
      <img className="pc-hidden" src={"/images/about/pc/all_time.jpg"} alt="" />
    </div>

    <div className="t-about__update">
      <div className="t-about__update__block">
        <h4 className="absolute-txt rot-left sp-hidden">UPDATE SKIN CARE</h4>
        <h2>UPDATE</h2>
        <h3>SKIN CARE.</h3>
        <p>変化し続けるスキンケア</p>
        <hr />
        <p className="t-about__update__txt">あなたのお肌は、気候、体調、年齢、心。<br />
          様々な要因で日々変化しています。<br />
          だから、お肌に必要なものもきっと変わるはず。<br />
          <br />
          HOTARU PERSONALIZEDは<br />
          肌状態データと使用感データを解析することで、<br className="sp-hidden" />
          その時その時のあなたに<br />
          ぴったりのスキンケアを提供します。<br />
          <br />
          見て、触れて、感じて。<br />
          今日のあなたのお肌に、耳を傾けてみて下さい。
        </p>
      </div>
      <img src={"/images/about/pc/update.jpg"} alt="" />
    </div>

    <div className="t-about__quality">
      <img className="sp-hidden" src={"/images/about/pc/quality.jpg"} alt="" />
      <div>
        <h2>QUALITY</h2>
        <h3>AND TRUST.</h3>
        <p>国内製薬メーカーのこだわり
          <br />お肌にとっての最善処方
        </p>
        <hr />
        <p className="t-about__quality__txt">HOTARU PERSONALIZEDのスキンケア<br className="sp-hidden" />
          アイテムは全て、国内製薬メーカーで<br />
          化粧品づくりのプロがお作りしています。<br />
          ”あなたのお肌にとっての最善”を第一に考え、<br className="sp-hidden" />
          やさしく丁寧に働きかけるような成分を処方。<br />
          <br />
          あなた本来の美しさを引き出します。
        </p>
        <h4 className="absolute-txt rot-right sp-hidden">QUALITY AND TRUST</h4>
      </div>
      <img className="pc-hidden" src={"/images/about/pc/quality.jpg"} alt="" />
    </div>

    <div className="t-about__logic">
      <h2>PERSONALIZED<br className="pc-hidden" /><span className="sp-hidden"> </span>LOGIC</h2>
      <h3>オーダーメイドで”今の”あなたの肌に<br className="pc-hidden" />合わせてWアプローチ</h3>
      <hr />
      <div className="t-about__logic__skin">
        <h4 className="absolute-txt rot-left sp-hidden">PERSONALIZED LOGIC</h4>
        <img className="pc-hidden" src={"/images/about/pc/logic_top.jpg"} alt="" />
        <div className="t-about__logic__skin__left">
          <p className="num">01</p>
          <h3>SKIN TROUBLE</h3>
          <p>あなたの<br />
            肌トラブルに<br />
            アプローチ
          </p>
          <picture>
            <source media="(max-width:959px)" srcSet={"/images/about/sp/arrow_1.png"} />
            <img className="arrow" src={"/images/about/pc/arrow_1.png"} alt="" />
          </picture>
        </div>
        <img className="sp-hidden" src={"/images/about/pc/logic_top.jpg"} alt="" />
        <div className="t-about__logic__skin__right">
          <p className="num">02</p>
          <h3>SKIN BARANCE</h3>
          <p>あなたの<br />
            肌バランスを<br />
            整える<br />
          </p>
          <picture>
            <source media="(max-width:959px)" srcSet={"/images/about/sp/arrow_2.png"} />
            <img className="arrow" src={"/images/about/pc/arrow_2.png"} alt="" />
          </picture>
        </div>
      </div>
      <div className="t-about__logic__1">
        <img src={"/images/about/pc/logic_1.jpg"} alt="" />
        <div className="t-about__logic__right">
          <div>
            <p className="t-about__logic__num">01</p>
            <hr />
            <p className="t-about__logic__cat">あなたの肌トラブルに<br />アプローチ</p>
          </div>
          <p className="t-about__logic__desc">あなたのお肌に現れたトラブルにアプローチする<br className="sp-hidden"/>
            54種の成分をブレンド
          </p>
          <div className="t-about__logic__bakuchiol">
            <div><p>バクチオール</p><p>オリゴペプチド-34</p></div>
            <div><p>グリチルリチン酸2K</p><p>水溶性プログリカン</p></div>
            <div><p>アカツメクサ花エキス</p><p>パルミトイルトリペプチド</p></div>
          </div>
          <p className="t-about__logic__and">AND MORE...</p>
        </div>
      </div>

      <div className="t-about__logic__2">
        <img className="pc-hidden" src={"/images/about/pc/logic_2.jpg"} alt="" />
        <div className="t-about__logic__left">
          <div>
            <p className="t-about__logic__num">02</p>
            <hr />
            <p className="t-about__logic__cat">あなたの肌バランスを<br />整える</p>
          </div>
          <p className="t-about__logic__desc">14万通りの診断結果から千差万別な肌も黄金バラ<br className="sp-hidden" />ンスゾーンへ</p>
          <div className="t-about__logic__approach">
            <h3>水分量にアプローチ</h3>
            <p className="t-about__logic__complex">アクアコンプレックス</p>
          </div>
          <img className="cross" src={"/images/about/pc/cross.png"} alt="" />
          <div className="t-about__logic__approach">
            <h3>油分量にアプローチ</h3>
            <p className="t-about__logic__complex">オイルコンプレックス</p>
          </div>
        </div>
        <img className="sp-hidden" src={"/images/about/pc/logic_2.jpg"} alt="" />
      </div>

      <div>
        <div className="css-arrow-down"></div>
      </div>
    </div>

    <div className="t-about__keep">
      <h2>KEEP</h2>
      <h3>SKIN IN CONDITION</h3>
      <p className="t-about__keep__cat">さらに理想の肌状態を維持する！</p>
      <h4 className="t-about__keep__txt">紫外線や摩擦など<br className="pc-hidden" />お肌に与えるダメージをブロック</h4>

      <div className="t-about__keep__point">
        <img src={"/images/about/pc/logic_bottom.jpg"} alt="" />
        <div className="circle">
          <div className="circle__ck"></div>
          <div className="circle__txt">5種の<br />セラミド</div>
        </div>
        <div className="circle">
          <div className="circle__ck"></div>
          <div className="circle__txt">15種の<br />アミノ酸</div>
        </div>
        <div className="circle">
          <div className="circle__ck"></div>
          <div className="circle__txt">2種の植物性<br />グロース<br />ファクター</div>
        </div>
      </div>

    </div>

    <div className="t-about__button">
      <Link to={process.env.QUESTION_URL_INTEGRATED_FORM} className="button">ONLINE肌診断(無料)</Link>
    </div>

    {/*
    <T_ABOUT_QUESTIONS />
    <Link class="p-pageBackBtn" to="/">
      <div class="c-grid">
        <div class="c-grid__row">
          <div class="c-grid__col">
            <span class="p-pageBackBtn__inner">BACK TO TOP</span>
          </div>
        </div>
      </div>
    </Link>
    */}
  </Layout>
)

export default AboutHotaruPage
